<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col>
          <v-autocomplete
            :items="objects"
            v-model="search"
            item-value="id"
            item-text="name"
            multiple
            clearable
            chips
            hide-details
            dense
            outlined
            background-color="white"
            placeholder="Buscar"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-btn 
            block 
            color="success" 
            large :disabled="!search" 
            @click="loadResume()"
          >Buscar</v-btn>
        </v-col>
      </v-row>
    </TheTitle>
    <v-card v-if="isSearching" class="my-10 pa-5">
      <div v-for="(chart, i) in charts" :key="i" class="pa-5">
        <h3>Dado: {{ chart.name }}</h3>
        <div :style="'height:' + chart.height + 'px'">
          <v-chart :option="chart"  />
        </div>
        <v-divider class="my-5"></v-divider>
      </div>
    </v-card>

    <v-dialog v-model="dialog" max-width="800px">
      <template>
        <v-card class="pa-5">
          {{ dataSelect }}
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import 'echarts'
import VChart from 'vue-echarts'

export default {
  name: 'Dados',
  components: {
    TheTitle,
    VChart,
  },
  data: () => ({
    baseRoute: 'data',
    title: 'Mapeamento de Dados',
    pageIcon: 'mdi-database-search',
    object: {},
    search: null,
    isSearching: false,
    dataSelect: {},
    objects: [],
    charts: [],
    extract: [],  
  }),
  methods: {

    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', null)
        })
    },
    loadResume() {
      const url = `${baseApiUrl}/report/data/map`
      this.$store.dispatch('setLoadingInfo', true)
      this.charts = []
      axios
        .post(url, { search: this.search })
        .then((res) => {
          this.extract = res.data.items
          this.createChart(res.data)
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', null)
        })
        this.isSearching = true;
    },
    
    createChart(data) {
      for (let index = 0; index < data.items.length; index++) {
        const element = data.items[index]
        const chart = {
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              let titular = params.data.titular ? params.data.titular : "Não Informado."
              let datagroupName = params.data.datagroupName ? params.data.datagroupName : "Não Informado."
              let activityName = params.data.activityName ? params.data.activityName : "Não Informado."
              let description = params.data.description ? params.data.description : "Não Informado."
              let name = params.data.name ? params.data.name : "Não Informado."
              let sensitivity = params.data.sensitivity ? params.data.sensitivity : "Não Informado."
              let place = params.data.place ? params.data.place : "Não Informado."
              let type = params.data.type ? params.data.type : "Não Informado."
              return `Atividade: ${activityName} </br>
                Descrição ${description} </br>
                Grupo: ${datagroupName} </br>
                Local: ${place} </br>
                Nome: ${name} </br>
                Sensibilidade: ${sensitivity} </br>
                Titular: ${titular} </br>
                Tipo: ${type} </br>`;
            }
          },
          name: element.name,
          height: element.size < 5 ? 400 : element.size * 80,
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          series: [
            {
              type: 'tree',
              id: index,
              name: `tree${index}`,
              data: [element],
              top: '0%',
              right: '500px',

              symbolSize: 20,
              edgeShape: 'polyline',
              edgeForkPosition: '63%',
              initialTreeDepth: 2,
              lineStyle: {
                width: 2,
              },
              label: {
                backgroundColor: '#fff',
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 16,
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left',
                  fontSize: 16,
                },
              },
              emphasis: {
                focus: 'descendant',
              },
              expandAndCollapse: true,
              animationDuration: 500,
              animationDurationUpdate: 750,
            },
          ],
        }
        this.charts.push(chart)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style>
.chart {
  height: 100px;
}
</style>
